import React from "react"
import { Chart as ChartJS, ChartData, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

interface IProps {
    data: ChartData<any>
}

function PieChart(props: IProps) {
    return <Pie data={props.data} options={{ maintainAspectRatio: false }} />
}

export default PieChart

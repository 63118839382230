//  Modules from the React eco-system
import React from "react"

// Screens
import Dashboard from "../pages/dashboard.page"
import Users from "../pages/users.page"
import Brews from "../pages/brews.page"
import Machines from "../pages/machines.page"
import UserDetails from "../pages/user-details.page"
import SuperadminPage from "../pages/superadmins.page"
import LoggedInUserPage from "../pages/profile.page"

// Interfaces, enums
import {ERoles} from "../constants/enum"

// Styles, bootstrap, icons
import {
    mdiAccountMultipleOutline,
    mdiAccountStarOutline,
    mdiChartDonut, mdiCoffeeMakerOutline, mdiCoffeeOutline,
} from "@mdi/js"
import NotFoundPage from "../pages/not-found.page"

export interface IDashboardRoute {
    path: string
    name: string
    exact: boolean
    icon: string // for the sidebar
    component: React.FunctionComponent
    displayOnSidebar: boolean // if false: it does not displayed on the sidebar
    permission: ERoles[] // for the filtering by the logged in user
}

const dashboardRoutes: IDashboardRoute[] = [
    {
        path: "/admin/dashboard",
        name: "Dashboard",
        exact: true,
        icon: mdiChartDonut,
        component: Dashboard,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/brews",
        name: "Brews",
        exact: true,
        icon: mdiCoffeeOutline,
        component: Brews,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/machines",
        name: "Machines",
        exact: true,
        icon: mdiCoffeeMakerOutline,
        component: Machines,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/users/main/:page?",
        name: "Users",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: Users,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/users/:id/:page?",
        name: "Users",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: UserDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/administrators/:page?",
        name: "Administrators",
        exact: true,
        icon: mdiAccountStarOutline,
        component: SuperadminPage,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/profile",
        name: "Own profile",
        exact: true,
        icon: mdiAccountStarOutline,
        component: LoggedInUserPage,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/404",
        name: "Not Found",
        exact: true,
        icon: mdiAccountStarOutline,
        component: NotFoundPage,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN],
    }
]

export default dashboardRoutes

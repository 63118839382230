// Own components
import UserInfo from "./user-info.component"

// Interfaces, enums
import { IUser } from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Col, Row } from "react-bootstrap"

interface IProps {
    user: IUser
}

/**
 * Main infos of the user
 * @param props 
 * @returns 
 */
function UserDetailsHome(props: IProps) {
    return (
        <div className="user-details">
            <Row>
                <Col xs={12} md={4}>
                    <UserInfo user={props.user} />
                </Col>
            </Row>
        </div>
    )
}

export default UserDetailsHome

import React, { useState } from "react"
import { Table } from "react-bootstrap"
import { useTable, useSortBy, useFilters, usePagination, useExpanded } from "react-table"
import { mdiSortBoolAscending, mdiDotsVertical, mdiSortBoolDescending, mdiSwapVertical, mdiChevronLeft, mdiChevronRight, mdiSkipForward, mdiSkipBackward } from "@mdi/js"
import Icon from "@mdi/react"
import {noop} from "lodash";

//TODO: itt kicsit sok az any nem ? 
// FIXME: egyelőre nem sikerült a type-okat rendbe tennem itt
interface IProps {
    columns: any
    pageCount?: any
    totalCount?: any
    pageSize?: any
    pageIndex?: any
    fetchData?: CallableFunction
    data: any
    renderRowSubComponent?: Function // function to render the subcomponent (displayed if the row is collapsed)
    paginationOptions?: any
}

export default function TableDisplayer({ columns, data, fetchData, renderRowSubComponent, paginationOptions, pageCount, pageSize: ps, pageIndex: pi, totalCount }: IProps) {
    const [showOptions, setShowOptions] = useState(false)

    // Use the useTable Hook to send the columns and data to build the table
    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        visibleColumns,
        page,
        nextPage,
        previousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
        canPreviousPage,
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        allColumns,
    } = useTable(
        {
            columns,
            data,
            manualPagination: !!pageCount,
            pageCount,
            pageSize: ps,
            pageIndex: pi,
            initialState: {
                pageIndex: pi,
                pageSize: (paginationOptions && paginationOptions.pageSize) || 10,
                hiddenColumns: columns.filter((column: any) => column?.isHidden).map((column: any) => column.accessor),
            },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    )

    React.useEffect(() => {
        console.log('FETCH');
        console.log(pageIndex);
        fetchData ? fetchData({ pageIndex, pageSize }) : noop()
    }, [pageIndex])

    return (
        <div className="table">
            <div className="table-actions">
                <div className="show-hide-columns" onClick={() => setShowOptions(!showOptions)}>
                    <Icon path={mdiDotsVertical} size={3} />
                </div>
                {showOptions && (
                    <div className="options">
                        <div className="col-selectors">
                            {allColumns.map((column) => (
                                <div className="selector-item" key={column.id}>
                                    <input className="checkbox" type="checkbox" {...column.getToggleHiddenProps()} />
                                    <label>{column.Header}</label>
                                </div>
                            ))}
                        </div>
                        <div>
                            <select className="pagination-selector" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                                {[10, 25, 50].map((pageSize) => {
                                    return (
                                        <option value={pageSize} key={pageSize}>
                                            {`Show ${pageSize}`}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                )}
            </div>
            <Table {...getTableProps()} borderless responsive>
                <thead className="filter-header">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    <div className="filter">{column.canFilter ? column.render("Filter") : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <thead className="header-title">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    <span className="title">{column.render("Header")}</span>

                                    <span {...column.getSortByToggleProps()}>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <Icon path={mdiSortBoolAscending} size={0.8} />
                                            ) : (
                                                <Icon path={mdiSortBoolDescending} size={0.8} />
                                            )
                                        ) : column.canSort ? (
                                            <Icon path={mdiSwapVertical} size={0.8} />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className={!page.length ? "empty-table" : ""} {...getTableBodyProps()}>
                    {!page.length && <p className="table-empty-state-message">There are no items in this list. Please create a new item!</p>}
                    {page && page.length && page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <>
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    })}
                                </tr>
                                {/* render subcomponent if the row is expanded */}
                                {row.isExpanded && renderRowSubComponent ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                                    </tr>
                                ) : null}
                            </>
                        )
                    })}
                </tbody>
            </Table>
            <div style={{'width': '100%', 'textAlign': 'center', 'borderTop': '0' }}>Total Items: {totalCount}</div>
            <div className="pagination">
                <div>
                    <span onClick={() => canPreviousPage && gotoPage(0)} className={!canPreviousPage ? "disabled" : ""}>
                        <Icon path={mdiSkipBackward} size={1} />
                    </span>
                    <span onClick={() => canPreviousPage && previousPage()} className={!canPreviousPage ? "disabled" : ""}>
                        <Icon path={mdiChevronLeft} size={1} />
                    </span>
                    <span>{` ${pageIndex + 1} / ${pageOptions.length} `}</span>
                    <span onClick={() => canNextPage && nextPage()} className={!canNextPage ? "disabled" : ""}>
                        <Icon path={mdiChevronRight} size={1} />
                    </span>
                    <span onClick={() => canNextPage && gotoPage(pageOptions.length-1)} className={!canNextPage ? "disabled" : ""}>
                        <Icon path={mdiSkipForward} size={1} />
                    </span>
                </div>
            </div>
        </div>
    )
}

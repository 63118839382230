import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IPageNameData {
    pageName: string
    pageUrl: string
    subPageName: string
}
interface IState extends IPageNameData {
    isSidebarNarrow: boolean
}

const initialState: IState = {
    pageName: "Dashboard",
    pageUrl: "/admin/dashboard",
    subPageName: "",
    isSidebarNarrow: false,
}

const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        setPageName: (state: IState, action: PayloadAction<IPageNameData>) => {
            return { ...state, ...action.payload }
        },
        toggleIsSidebarNarrow: (state: IState) => {
            return { ...state, isSidebarNarrow: !state.isSidebarNarrow }
        },
        reset: (state: any) => {
            return { ...initialState }
        },
    },
})

export const { setPageName, toggleIsSidebarNarrow, reset } = pageSlice.actions
export default pageSlice.reducer

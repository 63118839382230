//  Modules from the React eco-system
import { useState } from "react"
import { useHistory } from "react-router-dom"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Own components
import PhotoUploader from "../profil-image-uploader.component"

// Axios
import axios, { AxiosError } from "axios"
import axiosClient from "../../api/api"

// Interfaces, enums
import { IUser } from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Col, Row, Form, Button, OverlayTrigger, Popover } from "react-bootstrap"

// Images
import default_avatar from "../../assets/img/default-avatar.jpg"

interface IProps {
    user: IUser
    setUser: Function
}

/**
 * Update form and delete button for user
 * @param props
 * @returns
 */
function UserUpadate(props: IProps) {
    const history = useHistory()

    const [showImageUploader, setShowImageUploader] = useState(false)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: props.user.firstname,
            lastname: props.user.lastname,
            email: props.user.email,
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string(),
            lastname: Yup.string(),
            email: Yup.string().email("Az email cím nem megfeleő"),
        }),
        onSubmit: async (values, actions) => {
            try {
                if (props.user) {
                    const updateData = {
                        ...(values.firstname && values.firstname !== props.user.firstname && { firstname: values.firstname }),
                        ...(values.lastname && values.lastname !== props.user.lastname && { lastname: values.lastname }),
                        ...(values.email && values.email !== props.user.email && { email: values.email }),
                    }

                    if (Object.keys(updateData).length) {
                        const response = await axiosClient.patch(`/user/${props.user.id}`, updateData)
                        props.setUser(response.data)
                        toast.success("The user is updated")
                    }
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    /**
     * popover for the delete button
     */
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Delete User</Popover.Header>
            <Popover.Body>
                <div>
                    <p>Delete this user?</p>
                    <Button className="btn orange" onClick={deleteUser}>
                        Delete
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    )

    /**
     * handle delete user
     */
    async function deleteUser() {
        try {
            await axiosClient.delete(`/user/${props.user.id}`)
            history.push("/admin/users/main")
            toast.success("The user is deleted")
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Failed to delete the user")
            }
        }
    }

    return (
        <div className="user-update">
            <div className="tile edit-container">
                <Row>
                    <Col xs={12} sm={2} className="avatar-container">
                        <img className="user-picture" alt="profile-avatar" src={props.user.image ? props.user.image : default_avatar} />

                        <Button onClick={() => setShowImageUploader(true)} className="orange">
                            Upload image
                        </Button>
                    </Col>
                    <Col>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group className="mb-3" controlId="formSupplier">
                                <Form.Label>Firstname:</Form.Label>
                                <Form.Control onChange={formik.handleChange} name="firstname" onBlur={formik.handleBlur} value={formik.values.firstname} type="text" placeholder="Firstname..." />
                                {formik.touched.firstname && formik.errors.firstname ? <p className="input-error">{formik.errors.firstname}</p> : null}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formSupplier">
                                <Form.Label>Lastname:</Form.Label>
                                <Form.Control onChange={formik.handleChange} name="lastname" onBlur={formik.handleBlur} value={formik.values.lastname} type="text" placeholder="Lastname..." />
                                {formik.touched.lastname && formik.errors.lastname ? <p className="input-error">{formik.errors.lastname}</p> : null}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formSupplier">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control onChange={formik.handleChange} name="email" onBlur={formik.handleBlur} value={formik.values.email} type="email" placeholder="Email..." />
                                {formik.touched.email && formik.errors.email ? <p className="input-error">{formik.errors.email}</p> : null}
                            </Form.Group>

                            <Button className="orange" type="submit">
                                Update
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>

            <Row>
                <Col>
                    <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose>
                        <Button className="orange">Delete User</Button>
                    </OverlayTrigger>
                </Col>
            </Row>
            <PhotoUploader
                show={showImageUploader}
                handleClose={() => setShowImageUploader(false)}
                setPerson={(imageUrl: string) => props.setUser({ ...props.user, image: imageUrl })}
                personId={props.user.id}
                role={props.user.role}
            />
        </div>
    )
}

export default UserUpadate

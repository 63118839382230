import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import ActivityLogs from "../dashboard/activity-logs.component"
import { IUser } from "../../interfaces/persons"
import { IActivityLog } from "../../interfaces/brew"
import axiosClient from "../../api/api"

interface IProps {
    user: IUser
}

function UserActivity(props: IProps) {
    const [logs, setLogs] = useState<IActivityLog[]>([])

    useEffect(() => {
        fetchLogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchLogs() {
        try {
            const response = await axiosClient.get(`/log?actor=${props.user.id}`)
            setLogs(response.data)
        } catch (error) {
            console.log("Failed to fetch logs")
        }
    }
    return (
        <div>
            <Row>
                <Col>
                    <div className="table-tile">
                        <ActivityLogs logs={logs} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default UserActivity

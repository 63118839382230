//  Modules from the React eco-system
import React, { useState, useEffect, useRef } from "react"
import { Redirect, Route, Switch } from "react-router"

// Redux
import { useSelector } from "react-redux"
import { RootState } from "../store/store"

// Axios
import axiosClient from "../api/api"

// Routes
import dashboardRoutes, { IDashboardRoute } from "../routes/dashboard.route"

// Own components
import NavbarTop from "../components/navigation/navbar-top-component"
import SideBar from "../components/navigation/sidebar.component"

// interfaces, enums
import { IBaseAdmin } from "../interfaces/persons"
import { ERoles } from "../constants/enum"

// Styles, bootstrap, icons
import { Container } from "react-bootstrap"
import UserProvider, { defaultAdmin } from "../store/UserProvider"

// helper functions
import logout from "../helper/logout"

/**
 * the content of the admin page (for logged in admin)
 */
function AdminLayout() {
    // sidebar state from the redux
    const isSidebarNarrow = useSelector((state: RootState) => state.pageReducer.isSidebarNarrow)

    // state to store the data of the logged in admin
    const [adminDetails, setAdminDetails] = useState<IBaseAdmin>(defaultAdmin)

    // sidebar state, show, hide
    const [sidebarToggleClass, setsidebarToggleClass] = useState<string>("hide")
    const wrapperRef = useRef<HTMLDivElement>(null)
    const upperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        getUserData()
        document.addEventListener("mousedown", handleClickOutside, false)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false)
        }
    }, [])

    /**
     * fetch the data of the logged in admin
     */
    async function getUserData() {
        try {
            const userResponse = await axiosClient.get(`/auth/admin`)
            setAdminDetails(userResponse.data)
        } catch (error) {
            console.log("error", error)
            logout()
        }
    }

    function handleClickOutside(event: any) {
        if (wrapperRef.current && !wrapperRef?.current?.contains(event.target as Node)) {
            setsidebarToggleClass("hide")
        }
    }

    /**
     * render routes of the admin layout (by the dasboardRoutes)
     * @returns routes
     */
    function renderRoutes() {
        /**
         * 1. filter by roles (the loggedin user has permission to see the page?)
         * 2. map the route
         */
        return (
            <Container fluid>
                {adminDetails && (
                    <Switch>
                        <Route exact path="/admin">
                            <Redirect to="/admin/dashboard" />
                        </Route>
                        {dashboardRoutes
                            .filter((route: IDashboardRoute) => {
                                return route.permission.includes(adminDetails.role as ERoles)
                            })
                            .map((route: IDashboardRoute, key: number) => {
                                return (
                                    <Route
                                        key={key}
                                        path={route.path}
                                        exact={route.exact}
                                        render={() => {
                                            return <route.component />
                                        }}
                                    />
                                )
                            })}
                    </Switch>
                )}
            </Container>
        )
    }

    return (
        <div className="admin-layout" ref={upperRef}>
            <UserProvider>
                <div ref={wrapperRef} className="sidebar-container">
                    <SideBar routes={dashboardRoutes} sidebarToggleClass={sidebarToggleClass} setsidebarToggleClass={setsidebarToggleClass} />
                </div>
                <div className={`content-panel ${isSidebarNarrow ? "narrow-sidebar" : ""}`}>
                    <NavbarTop setsidebarToggleClass={setsidebarToggleClass} />
                    <div className="content-area">{renderRoutes()}</div>
                </div>
            </UserProvider>
        </div>
    )
}

export default AdminLayout

import React from "react"
import { Modal } from "react-bootstrap"
import ReactJson from "react-json-view"

interface IProps {
    show: boolean
    onHide: any
    data: any
}

function ActivityLogModal(props: IProps) {
    return (
        <Modal className="activity-data-modal" show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReactJson src={props.data} name="data" iconStyle="square" collapsed={true} collapseStringsAfterLength={20} displayDataTypes={false} displayObjectSize={false} />
            </Modal.Body>
        </Modal>
    )
}

export default ActivityLogModal

//  Modules from the React eco-system
import React, { useState, createContext, useEffect } from "react"

// Axios
import axiosClient from "../api/api"

// Redux
import { useDispatch } from "react-redux"
import { setIsAuthenticated } from "./userSlice"

// Interfaces, enums
import { IBaseAdmin } from "../interfaces/persons"

// helper functions
import logout from "../helper/logout"

export const defaultAdmin: IBaseAdmin = {
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password: "",
    phone: "",
    role: "",
    isVerified: true,
    createdAt: new Date(),
}
export const UserContext = createContext({ user: defaultAdmin, getUserData: () => {}, updateUser: (user: IBaseAdmin) => {} })

const UserProvider = ({ children }: any) => {
    const dispatch = useDispatch()

    // User is the name of the "data" that gets stored in context
    const [user, setUser] = useState(defaultAdmin)

    // get the userdata when the app starts --> set isAuthenticated to true or false
    useEffect(() => {
        getUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getUserData() {
        try {
            const userResponse = await axiosClient.get(`/auth/admin`)
            setUser(userResponse.data)
            dispatch(setIsAuthenticated(true))
        } catch (error) {
            setUser(defaultAdmin)
            dispatch(setIsAuthenticated(false))
            logout()
        }
    }

    function updateUser(user: IBaseAdmin) {
        setUser(user)
    }

    return <UserContext.Provider value={{ user: user, getUserData, updateUser }}>{children}</UserContext.Provider>
}

export default UserProvider

import React from "react"
import Icon from "@mdi/react"
import { mdiRobotConfused } from "@mdi/js"

function NotFoundPage() {
    return (
        <div className="not-found-page">
            <div className="not-found-container">
                <Icon className="icon" path={mdiRobotConfused} />
                <p className="error-code">404</p>
                <p className="not-found-text">Not Found</p>
            </div>
        </div>
    )
}

export default NotFoundPage

//  Modules from the React eco-system
import React from "react"
import { useHistory, useParams } from "react-router"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// axios
import axios, { AxiosError } from "axios"
import { apiUrl } from "../config/config"

// style, bootstrap, icons
import { Form, Button } from "react-bootstrap"

// images
import Logo from "../assets/img/logo_transparentbg.png"

interface RouteParams {
    token: string
}

function ForgotPassword() {
    const history = useHistory()

    const { token } = useParams<RouteParams>()

    const formik = useFormik({
        initialValues: {
            password: "",
            passwordAgain: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Password is a required field"),
            passwordAgain: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                }
                await axios.post(`${apiUrl}/auth/admin/forgot-password/change`, values, config)
                toast.success("Password changed")
                resetForm()
                history.push("/login")
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    const err = error as AxiosError
                    toast.error(err.response?.data)
                } else {
                    toast.error("Failed to change password")
                }
            }
        },
    })
    return (
        <div className="login-screen">
            <div className="login-container">
                <div className="logo-container">
                    <Logo />
                </div>

                <div className="form-container">
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="form-group" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control onChange={formik.handleChange} name="password" onBlur={formik.handleBlur} value={formik.values.password} type={"password"} placeholder="Password" />

                            {formik.touched.password && formik.errors.password ? <Form.Text className="input-error">{formik.errors.password}</Form.Text> : null}
                        </Form.Group>
                        <Form.Group className="form-group" controlId="formBasicPassword">
                            <Form.Label>Password Again</Form.Label>
                            <Form.Control
                                onChange={formik.handleChange}
                                name="passwordAgain"
                                onBlur={formik.handleBlur}
                                value={formik.values.passwordAgain}
                                type={"password"}
                                placeholder="Password again"
                            />

                            {formik.touched.passwordAgain && formik.errors.passwordAgain ? <Form.Text className="input-error">{formik.errors.passwordAgain}</Form.Text> : null}
                        </Form.Group>
                        <Button variant="primary" type="submit" className="login-button orange">
                            Change Password
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword

//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// Own components
import BrewsTable from "../components/brews/brews-table.component"
import Loader from "../components/loader.component"

// Axios
import axiosClient from "../api/api"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

// Interfaces, enums
import { IBrew } from "../interfaces/brew"
import {ceil} from "lodash";

function Brews() {
    const dispatch = useDispatch()

    const [brews, setBrews] = useState<IBrew[]>([])
    const [pageCount, setPageCount] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [pageIndex, setPageIndex] = useState<number>(0)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(setPageName({ pageName: "Brews", pageUrl: "/admin/brews", subPageName: "" }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onDelete(brewId: string){
       // await fetchBrews({ pageSize, pageIndex });
    }

    // @ts-ignore
    const fetchBrews = async ({ pageSize: pageSize, pageIndex: pageIndex }) => {
        //setIsLoading(true)
        try {
            setPageSize(pageSize)
            setPageIndex(pageIndex)
            const response = await axiosClient.get(`/brew/list?pageSize=${pageSize}&pageIndex=${pageIndex}`)
            const {pager: { totalCount } } = response.data;
            setPageCount(ceil(totalCount / pageSize))
            setTotalCount(totalCount);
            setBrews(response.data.records)
        } catch (error) {
            console.log("Failed to fetch brews")
            setBrews([])
            setPageCount(1)
        }
        //setIsLoading(false)
    }
    const fetchData = React.useCallback(fetchBrews, [])

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="table-tile">
                        <BrewsTable
                            brews={brews}
                            totalCount={totalCount}
                            pageCount={pageCount}
                            pageSize={pageSize}
                            pageIndex={pageIndex}
                            fetchData={fetchData}
                            onDelete={onDelete} hiddenColumns={[]} />
                    </div>
                </>
            )}
        </div>
    )
}

export default Brews

import { configureStore } from "@reduxjs/toolkit"

import userReducer from "./userSlice"
import pageReducer from "./pageSlice"

const store = configureStore({
    reducer: {
        userReducer: userReducer,
        pageReducer: pageReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export default store

//  Modules from the React eco-system
import React, { useState, useEffect, useContext } from "react"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"
import Loader from "../components/loader.component"

// Navigation
import usersMainRoutes from "../routes/mains/users-main.route"
import { Route, Switch, useHistory } from "react-router-dom"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"
import { UserContext } from "../store/UserProvider"

// Axios
import axiosClient from "../api/api"

// Interfaces, enums
import { IUser } from "../interfaces/persons"
import { ISubPageRoute } from "../interfaces/pages"
import { ERoles } from "../constants/enum"
import {ceil} from "lodash";
import UsersTable from "../components/users/users-table.component";

function Users() {
    //Logged in user
    const { user } = useContext(UserContext)

    const dispatch = useDispatch()
    const history = useHistory()

    const [users, setUsers] = useState<IUser[]>([])
    const [pageCount, setPageCount] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [pageIndex, setPageIndex] = useState<number>(0)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(setPageName({ pageName: "Users", pageUrl: "/admin/users/main", subPageName: "" }))

        //fetchUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // @ts-ignore
    async function fetchUsers({ pageSize: pageSize, pageIndex: pageIndex }) {
        try {
            setPageSize(pageSize)
            setPageIndex(pageIndex)
            const response = await axiosClient.get(`/user/list?pageSize=${pageSize}&pageIndex=${pageIndex}`)
            const {pager: { totalCount } } = response.data;
            setPageCount(ceil(totalCount / pageSize))
            setTotalCount(totalCount);
            setUsers(response.data.records)
        } catch (error) {
            console.log("Failed to fetch users")
            setUsers([])
            setPageCount(1)
        }
        setIsLoading(false)
    }
    const fetchData = React.useCallback(fetchUsers, [])

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <SubPageNavbar
                        routes={usersMainRoutes.filter((route: ISubPageRoute) => {
                            return route.permission?.includes(user.role as ERoles)
                        })}
                    />

                    <Switch>
                        {usersMainRoutes
                            .filter((route: ISubPageRoute) => {
                                return route.permission?.includes(user.role as ERoles)
                            })
                            .map((route: ISubPageRoute, key: number) => {
                                return (
                                    <Route
                                        key={key}
                                        path={route.path}
                                        exact
                                        render={() => {
                                            return (
                                                <div>
                                                    <UsersTable users={users}
                                                                totalCount={totalCount}
                                                                pageCount={pageCount}
                                                                pageSize={pageSize}
                                                                pageIndex={pageIndex}
                                                                fetchData={fetchData}
                                                    />
                                                </div>
                                            )
                                        }}
                                    />
                                )
                            })}
                    </Switch>
                </>
            )}
        </div>
    )
}

export default Users

import UserActivity from "../../components/users/user-activity.component"
import UserDetailsHome from "../../components/users/user-details-home.component"
import UserBrews from "../../components/users/user-brews.component"
import UserUpadate from "../../components/users/user-update.component"
import { ERoles } from "../../constants/enum"
import { ISubPageRoute } from "../../interfaces/pages"

const userDetailsRoutes: ISubPageRoute[] = [
    {
        path: "/admin/users/:id",
        name: "Details",
        component: UserDetailsHome,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/users/:id/update",
        name: "Edit",
        component: UserUpadate,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/users/:id/brews",
        name: "Brews",
        component: UserBrews,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/users/:id/activity",
        name: "Activity",
        component: UserActivity,
        permission: [ERoles.SUPERADMIN],
    },
]

export default userDetailsRoutes

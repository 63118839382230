//  Modules from the React eco-system

// Third party modules
import moment from "moment"

// Own components
import ChartEvents from "../../components/charts/chart-events.component"
import PieChart from "../../components/charts/pie-chart.component"

// Interfaces, enums
import { IBrew } from "../../interfaces/brew"
import { IData } from "../../pages/dashboard.page"

// Styles, bootstrap, icons
import { Col, Row } from "react-bootstrap"

interface IProps {
    data: IData
}

function DashboardCharts(props: IProps) {
    /**
     * create data for reservation chart
     * @param reservations
     * @returns ChartData
     */
    function createEventChartData() {
        // get reservations of the current year
        const eventsInThisYear = props.data.brews.filter((brew: IBrew) => moment(new Date()).isSame(brew.createdAt, "year"))
        // create array filled with zeros
        const datesByMonths = new Array(12).fill(0)
        // count the reservations by month
        eventsInThisYear.forEach((brew: IBrew) => {
            const monthOfRes = new Date(brew.createdAt).getMonth()
            datesByMonths[monthOfRes]++
        })
        // create array of months as labels of the chart
        const months = Array.from({ length: 12 }, (item, i) => {
            return new Date(0, i).toLocaleString("en-US", { month: "long" })
        })

        return {
            labels: months,
            datasets: [
                {
                    label: "Brews in this year",
                    backgroundColor: "#008aad",
                    borderWidth: 0,
                    fill: false,
                    data: datesByMonths,
                },
            ],
        }
    }

    /**
     * create data for pie chart about the activity of the users
     * @returns ChartData
     */
    function createPieChartData() {
        // get the 5 most active users
        const mostActiveUsers = props.data.users
            .sort(function (aUser, bUser) {
                return parseInt(bUser.brewCount) - parseInt(aUser.brewCount)
            })
            .slice(0, 5)
        return {
            labels: mostActiveUsers.map((user) => `${user.firstname} ${user.lastname}`),
            datasets: [
                {
                    label: "Most active users",
                    data: mostActiveUsers.map((user) => parseInt(user.brewCount || 0)),
                    backgroundColor: ["#f2552c", "#00a66e", "#008aad", "#fb8b23", "#00a66e", "#cd212a", "#7c7c7c"],
                    borderWidth: 1,
                },
            ],
        }
    }
    return (
        <div className="dashboard-charts">
            <Row>
                <Col xs={12 }md={6}>
                    <div className="tile reservation-chart-container">

                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className="tile pie">
                        <PieChart data={createPieChartData()} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default DashboardCharts

// Modules from the React eco-system
import React from "react"

// Third-party modules
import { Formik, FormikValues } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// Styles, bootstrap, icons
import { Form, Button, Row, Col } from "react-bootstrap"

interface IProps {
    addNewAdmin: Function
}

const FormInitialValues = {
    firstname: "",
    lastname: "",
    email: "",
}

function CreateSuperadmin(props: IProps) {
    return (
        <Row>
            <Col>
                <div className="tile">
                    <Formik
                        initialValues={FormInitialValues}
                        validationSchema={Yup.object().shape({
                            firstname: Yup.string().required("Firstname is required"),
                            lastname: Yup.string().required("Lastname is required"),
                            email: Yup.string().email("Invalid email address"),
                        })}
                        onSubmit={async (values: FormikValues, actions: any) => {
                            try {
                                const adminData = {
                                    firstname: values.firstname,
                                    lastname: values.lastname,
                                    email: values.email,
                                }
                                const response = await axiosClient.post("/superadmin", { adminData: adminData })
                                actions.resetForm(FormInitialValues)
                                toast.success("Invitation email sent")
                                props.addNewAdmin(response.data)
                            } catch (error) {
                                const err = error as AxiosError
                                toast.error(err.response?.data)
                            }
                        }}>
                        {(props: any) => (
                            <Form onSubmit={props.handleSubmit}>
                                <Row>
                                    <Col xs={12} sm={4}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Firstname:</Form.Label>
                                            <Form.Control
                                                onChange={props.handleChange}
                                                name="firstname"
                                                onBlur={props.handleBlur}
                                                value={props.values.firstname}
                                                type="text"
                                                placeholder="Firstname..."
                                            />
                                            {props.touched.firstname && props.errors.firstname ? <p className="input-error">{props.errors.firstname}</p> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Lastname:</Form.Label>
                                            <Form.Control onChange={props.handleChange} name="lastname" onBlur={props.handleBlur} value={props.values.lastname} type="text" placeholder="Lastname..." />
                                            {props.touched.lastname && props.errors.lastname ? <p className="input-error">{props.errors.lastname}</p> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Email:</Form.Label>
                                            <Form.Control onChange={props.handleChange} name="email" onBlur={props.handleBlur} value={props.values.email} type="email" placeholder="Email..." />
                                            {props.touched.email && props.errors.email ? <p className="input-error">{props.errors.email}</p> : null}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Button className="orange" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Col>
        </Row>
    )
}

export default CreateSuperadmin

//  Modules from the React eco-system
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

// Own components
import MachinesTable from "../components/brews/machines-table.component"
import Loader from "../components/loader.component"

// Axios
import axiosClient from "../api/api"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

// Interfaces, enums
import { IMachine } from "../interfaces/brew"
import {ceil} from "lodash";

function Machines() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [machines, setMachines] = useState<IMachine[]>([])
    const [pageCount, setPageCount] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(1000)
    const [pageIndex, setPageIndex] = useState<number>(0)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(setPageName({ pageName: "Machines", pageUrl: "/admin/machines", subPageName: "" }))

        //fetchMachines()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onDelete(machineId: string){
        //await fetchMachines();
    }

    // @ts-ignore
    async function fetchMachines({ pageSize: pageSize, pageIndex: pageIndex }) {
        setPageSize(pageSize)
        setPageIndex(pageIndex)
        try {
            const response = await axiosClient.get(`/machine/list?pageSize=${pageSize}&pageIndex=${pageIndex}`)
            const {pager: { totalCount } } = response.data;
            setPageCount(ceil(totalCount / pageSize))
            setTotalCount(totalCount);
            setMachines(response.data.records)
        } catch (error) {
            console.log("Failed to fetch machines")
            //history.push("/admin/404")
        }
    }

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="table-tile">
                        <MachinesTable
                            pageCount={pageCount}
                            totalCount={totalCount}
                            pageSize={pageSize}
                            pageIndex={pageIndex}
                            fetchData={fetchMachines}
                            machines={machines} onDelete={onDelete} hiddenColumns={[]} />
                    </div>
                </>
            )}
        </div>
    )
}

export default Machines

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import Cookies from "universal-cookie"

const cookies = new Cookies()

interface IUserState {
    isAuthenticated: boolean
}

const initialState: IUserState = {
    isAuthenticated: cookies.get("token") ? true : false,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsAuthenticated: (state: IUserState, action: PayloadAction<boolean>) => {
            return { ...state, isAuthenticated: action.payload }
        },
        reset: () => {
            return { ...initialState }
        },
    },
})

export const { setIsAuthenticated, reset } = userSlice.actions
export default userSlice.reducer

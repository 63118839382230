import React, { useMemo, useState } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import {Button, Modal} from "react-bootstrap"
import { IMachine } from "../../interfaces/brew"
import { IUser } from "../../interfaces/persons"
import TableDisplayer from "../table.component"
import { textFilter, dateFilter } from "../column-filter.component"
import Icon from "@mdi/react"
import {mdiDelete, mdiTune} from "@mdi/js"
import {useTranslation} from "react-i18next";
import { toast } from "react-toastify"
import axiosClient from "../../api/api"
import axios, { AxiosError } from "axios"
import ReactJson from "react-json-view";

interface IProps {
    machines: IMachine[]
    fetchData: CallableFunction
    onDelete?: CallableFunction
    userId?: string
    pageCount: any,
    totalCount: any,
    pageSize: any,
    pageIndex: any,
    hiddenColumns?: string[]
}

// TODO: proper types (Cell data)
function MachinesTable(props: IProps) {
    const { t } = useTranslation();
    const [urlFilter, setUrlFilter] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedMachine, setSelectedMachine] = useState<any>(null);

    const handleDeleteClose = () => setShowDelete(false);

    function filterDates(rows:any, id:any, filterValue:any) {
        setUrlFilter(filterValue);
        return rows.filter((row:any) => {
            let filterFrom = filterValue[0];
            let filterTo = filterValue[1];
            const rowValue = row.values[id]
            if(filterFrom && filterFrom.split(' ').length === 1){
                filterFrom += ' 00:00:00'
            }
            if(filterTo && filterTo.split(' ').length === 1){
                filterTo += ' 23:59:59'
            }
            return moment(rowValue).isBetween(filterFrom, filterTo)
        })
    }

    async function deleteMachine(machineId: string) {
        try {
            await axiosClient.delete(`/machine/${machineId}`)
            if(props.onDelete){
                props.onDelete(machineId);
            }
            toast.success("Machine is deleted")
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Machine delete failed")
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("id"),
            },
            {
                Header: "Brand",
                accessor: "brand",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("brand"),
            },
            {
                Header: "Type",
                accessor: "type",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("type"),
            },
            {
                Header: t("User"),
                accessor: (data: IMachine) => {
                    return `${(data.user as IUser).firstname} ${(data.user as IUser).lastname}`
                },
                Cell: (data: any) => {
                    return <Link to={`/admin/users/${data.row.original.user.id}`}>{data.value}</Link>
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("user"),
            },
            {
                Header: t("Created At"),
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <>
                        <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                        <p style={{fontSize: '10px'}}>{moment(data.row.original.startedAt).format("YYYY. MM. DD HH:mm")} - {moment(data.row.original.finishedAt).format("YYYY. MM. DD HH:mm")}</p>
                    </>
                },
                Filter: dateFilter,
                filter: filterDates,
                isHidden: props.hiddenColumns?.includes("createdAt"),
            },
            {
                Header: t("Actions"),
                Cell: (data: any) => {
                    return (
                        <div onClick={() => { setSelectedMachine(data.row.original); setShowDelete(true);  }}>
                            <span className="icon-container btn orange">
                                <Icon path={mdiDelete} size={1} />
                            </span>
                        </div>
                    )
                },
                disableFilters: true,
            },
        ],
        [props.hiddenColumns]
    )

    return <div>
        {props.machines && <TableDisplayer
            columns={columns}
            pageCount={props.pageCount}
            totalCount={props.totalCount}
            pageSize={props.pageSize}
            pageIndex={props.pageIndex}
            fetchData={props.fetchData}
            data={props.machines} />}
            {selectedMachine && <Modal className="activity-data-modal" show={showDelete} onHide={handleDeleteClose} >
            <Modal.Header closeButton>
                <Modal.Title>Delete Machine</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Delete machine {selectedMachine.brand} {selectedMachine.type}?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="" onClick={handleDeleteClose}>
                    Close
                </Button>
                <Button className={'orange'} variant="primary" onClick={async() => { await deleteMachine(selectedMachine.id); handleDeleteClose()}} >Delete</Button>
            </Modal.Footer>
        </Modal>}
    </div>
}

export default MachinesTable

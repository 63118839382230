import React, { useState, useCallback, FormEvent } from "react"
import { Button, Modal } from "react-bootstrap"
import Cropper from "react-easy-crop"
import { Point, Area } from "react-easy-crop/types"

import getCropped from "../helper/crop-image"
import axiosClient from "../api/api"
import { ERoles } from "../constants/enum"

/**
 * Local interface for input properties.
 */
interface IModalProps {
    show: boolean
    handleClose: any
    personId: string
    setPerson: Function
    role: ERoles
}

function PhotoUploader(props: IModalProps) {
    const [uploadedImage, setUploadedImage] = useState<string>("")
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<number>(1)
    const [croppedImage2, setCroppedImage2] = useState<Blob | null>()

    const onCropComplete = useCallback(
        async (croppedArea: Area, croppedAreaPixels: Area) => {
            const newCroppedImage: Blob | null | undefined = await getCropped(uploadedImage, croppedAreaPixels)
            if (newCroppedImage) {
                setCroppedImage2(newCroppedImage)
            }
        },
        [uploadedImage]
    )

    async function saveCroppedImage() {
        setCroppedImage2(null)
        setUploadedImage("")
        setZoom(1)
        setCrop({ x: 0, y: 0 })

        const formdata = new FormData()

        try {
            if (croppedImage2) {
                let uploadedFile = new File([croppedImage2], "image.jpg", { type: "image/jpeg" })

                formdata.append("type", props.role.toLowerCase())
                formdata.append("image", uploadedFile)

                const response = await axiosClient.patch(`/${props.role.toLowerCase()}/${props.personId}/image`, formdata, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })

                props.setPerson(response.data.image)
            }
        } catch (error) {
            console.log("Error while uploading photo: ", error)
        }

        props.handleClose()
    }

    async function fileUpload(event: FormEvent) {
        const target = event.target as HTMLInputElement
        const files = target.files

        if (files?.length) {
            setUploadedImage(URL.createObjectURL(files[0]))
        }
    }

    function close() {
        setUploadedImage("")
        setCroppedImage2(null)
        setZoom(1)
        setCrop({ x: 0, y: 0 })
        props.handleClose()
    }

    return (
        <div>
            <Modal show={props.show} onHide={close} dialogClassName={"photo-uploader"}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload profile photo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="body">
                    <div className="crop-container">
                        {uploadedImage ? (
                            <Cropper image={uploadedImage} crop={crop} zoom={zoom} aspect={4 / 4} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
                        ) : (
                            <div className="upload-container">
                                <label htmlFor="single" className="link-button btn">
                                    Upload profile photo
                                </label>
                                <input className="upload-button" type="file" id="single" onChange={fileUpload} hidden />
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="orange" onClick={saveCroppedImage}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PhotoUploader

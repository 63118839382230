//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// axios
import axiosClient from "../../api/api"

// Interfaces, enums
import { IBrew } from "../../interfaces/brew"
import { IUser } from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"
import BrewsTable from "../brews/brews-table.component";

interface IProps {
    user: IUser
}

/**
 * display reservations of one user
 * @param props user
 * @returns
 */
function UserBrews(props: IProps) {
    // reservations of the user from the database
    const [brews, setBrews] = useState<IBrew[]>([])
    const [pageCount, setPageCount] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [pageIndex, setPageIndex] = useState<number>(0)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // fetch events
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * fetch reservations of the user from the database
     */
    // @ts-ignore
    const fetchBrews = async ({ pageSize, pageIndex }) => {
       // setIsLoading(true)
        try {
            setPageSize(pageSize)
            setPageIndex(pageIndex)
            const response = await axiosClient.get(`/brew/list?user_id=${props.user.id}&pageSize=${pageSize}&pageIndex=${pageIndex}`)
            const {pager: { totalCount } } = response.data;
            setTotalCount(totalCount)
            setBrews(response.data)
        } catch (error) {
            console.log("Failed to fetch brews")
            setBrews([])
        }
      //  setIsLoading(false)
    }
    const fetchData = React.useCallback(fetchBrews, [])

    return (
        <Row>
            <Col>
                <div className="table-tile">
                    <BrewsTable fetchData={fetchData}
                                pageCount={pageCount}
                                totalCount={totalCount}
                                pageSize={pageSize}
                                pageIndex={pageIndex}
                                brews={brews} hiddenColumns={[]} />
                </div>
            </Col>
        </Row>
    )
}

export default UserBrews

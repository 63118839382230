//  Modules from the React eco-system
import { Link } from "react-router-dom"
import i18next from "i18next";

// Styles, bootstrap, icons
import { Col, Row, Card } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiCalendarBlankOutline, mdiCubeOutline, mdiMapLegend, mdiAccountMultipleOutline } from "@mdi/js"

// Interfaces, enums
import { IData } from "../../pages/dashboard.page"
import {useTranslation} from "react-i18next";

interface IProps {
    data: IData
}

function InfoCards(props: IProps) {
    const { t } = useTranslation();
    return (
        <div className="card-section section">
            <Row>
                <Col xs={12} md={4}>
                    <div className="tile">
                        <Card.Header>
                            <div className="icon orange">
                                <Icon path={mdiAccountMultipleOutline} size={1.7} />
                            </div>
                            <div className="card-count">
                                <h4>{props.data.users ? props.data.users.length : 0}</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>
                                <Link to="/admin/users/main">{t('Users')}</Link>
                            </Card.Title>
                        </Card.Body>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className="tile">
                        <Card.Header>
                            <div className="icon">
                                <Icon path={mdiMapLegend} size={1.7} />
                            </div>
                            <div className="card-count">
                                <h4>{props.data.machines ? props.data.machines.length : 0}</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>
                                <Link to="/admin/machines/main">{t('Machines')}</Link>
                            </Card.Title>
                        </Card.Body>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className="tile">
                        <Card.Header>
                            <div className="icon">
                                <Icon path={mdiCalendarBlankOutline} size={1.7} />
                            </div>
                            <div className="card-count">
                                <h4>{props.data.brews ? props.data.brews.pager.totalCount : 0}</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>
                                <Link to="/admin/brews">{t('Brews')}</Link>
                            </Card.Title>
                        </Card.Body>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default InfoCards

//  Modules from the React eco-system
import React from "react"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Axios
import axiosClient from "../api/api"
import { AxiosError } from "axios"

// Styles, bootstrap, icons
import { Form, Button } from "react-bootstrap"

const FormInitialValues = {
    oldPassword: "",
    password: "",
    passwordConfirm: "",
}

/**
 * Change password form
 */
function ChangePassword() {
    const formik = useFormik({
        initialValues: FormInitialValues,
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Requiered"),
            password: Yup.string().required("Requiered"),
            passwordConfirm: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Requiered"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                await axiosClient.patch("/auth/admin/changepassword", values)
                toast.success("The password is updated")
                resetForm({
                    values: FormInitialValues,
                })
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <div className="tile">
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control onChange={formik.handleChange} name="oldPassword" onBlur={formik.handleBlur} value={formik.values.oldPassword} type="password" placeholder="Old password..." />
                    {formik.touched.oldPassword && formik.errors.oldPassword ? <p className="input-error">{formik.errors.oldPassword}</p> : null}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control onChange={formik.handleChange} name="password" onBlur={formik.handleBlur} value={formik.values.password} type="password" placeholder="Password..." />
                    {formik.touched.password && formik.errors.password ? <p className="input-error">{formik.errors.password}</p> : null}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>New Password Again</Form.Label>
                    <Form.Control
                        onChange={formik.handleChange}
                        name="passwordConfirm"
                        onBlur={formik.handleBlur}
                        value={formik.values.passwordConfirm}
                        type="password"
                        placeholder="Password again..."
                    />
                    {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? <p className="input-error">{formik.errors.passwordConfirm}</p> : null}
                </Form.Group>

                <Button className="orange" type="submit">
                    Create
                </Button>
            </Form>
        </div>
    )
}

export default ChangePassword

import React from "react"
import { IUser } from "../../interfaces/persons"
import default_avatar from "../../assets/img/default-avatar.jpg"
import moment from "moment"

interface IProps {
    user: IUser
}

function UserInfo(props: IProps) {
    return (
        <div className="tile operator-info">
            <div className="avatar">
                <img className="user-picture" alt="profile-avatar" src={props.user.image ? props.user.image : default_avatar} />
            </div>
            <p>user</p>
            <h2>{props.user ? `${props.user.firstname} ${props.user.lastname}` : "-"}</h2>
            <p>Email: {props.user ? props.user.email : "-"}</p>
            <p>Registartion: {moment(props.user.createdAt).format("YYYY.MM.DD.")}</p>
        </div>
    )
}

export default UserInfo

export enum ERoles {
    SUPERADMIN = "SuperAdmin", // full admin access
    USER = "User"
}

export enum EActivityAction {
    POST = "post",
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    LOGIN = "login",
}

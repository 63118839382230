//  Modules from the React eco-system
import React from "react"

// Third-party modules
import { Formik, FormikValues } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// Styles, bootstrap, icons
import { Form, Button, Row, Col } from "react-bootstrap"

const FormInitialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
}

function CreateUser() {
    return (
        <div>
            <div>
                <Formik
                    initialValues={FormInitialValues}
                    validationSchema={Yup.object().shape({
                        firstname: Yup.string().required("Firstname is required"),
                        lastname: Yup.string().required("Lastname is required"),
                        email: Yup.string().required("Az email cím megadása kötelező").email("Az email cím nem megfeleő"),
                        phone: Yup.string().required("A telefonszám megadása kötelező"),
                        password: Yup.string().min(6, "A jelszónak minimum 6 karakter hosszúnak kell lennie").required("A jelszó megadása kötelező"),
                        confirmPassword: Yup.string()
                            .oneOf([Yup.ref("password"), null], "A jelszavaknak egyezniük kell")
                            .required("A jelszó megerősítése kötelező"),
                    })}
                    onSubmit={async (values: FormikValues, actions: any) => {
                        try {
                            const userData = {
                                firstname: values.firstname,
                                lastname: values.lastname,
                                email: values.email,
                                password: values.password,
                                phone: values.phone,
                            }
                            await axiosClient.post("/user/registration", userData)
                            toast.success("User is created")
                            actions.resetForm(FormInitialValues)
                        } catch (error) {
                            const err = error as AxiosError
                            toast.error(err.response?.data)
                        }
                    }}>
                    {(props: any) => (
                        <Row>
                            <Col>
                                <div className="tile">
                                    <Form onSubmit={props.handleSubmit}>
                                        <Row>
                                            <Col xs={12} sm={3}>
                                                <Form.Group className="mb-3" controlId="formSupplier">
                                                    <Form.Label>Firstname:</Form.Label>
                                                    <Form.Control
                                                        onChange={props.handleChange}
                                                        name="firstname"
                                                        onBlur={props.handleBlur}
                                                        value={props.values.firstname}
                                                        type="text"
                                                        placeholder="Firstname..."
                                                    />
                                                    {props.touched.firstname && props.errors.firstname ? <p className="input-error">{props.errors.firstname}</p> : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <Form.Group className="mb-3" controlId="formSupplier">
                                                    <Form.Label>Lastname:</Form.Label>
                                                    <Form.Control
                                                        onChange={props.handleChange}
                                                        name="lastname"
                                                        onBlur={props.handleBlur}
                                                        value={props.values.lastname}
                                                        type="text"
                                                        placeholder="Lastname..."
                                                    />
                                                    {props.touched.lastname && props.errors.lastname ? <p className="input-error">{props.errors.lastname}</p> : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <Form.Group className="mb-3" controlId="formSupplier">
                                                    <Form.Label>Email:</Form.Label>
                                                    <Form.Control onChange={props.handleChange} name="email" onBlur={props.handleBlur} value={props.values.email} type="email" placeholder="Email..." />
                                                    {props.touched.email && props.errors.email ? <p className="input-error">{props.errors.email}</p> : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <Form.Group className="mb-3" controlId="formSupplier">
                                                    <Form.Label>Telefonszám:</Form.Label>
                                                    <Form.Control
                                                        onChange={props.handleChange}
                                                        name="phone"
                                                        onBlur={props.handleBlur}
                                                        value={props.values.phone}
                                                        type="text"
                                                        placeholder="Telefonszám..."
                                                    />
                                                    {props.touched.phone && props.errors.phone ? <p className="input-error">{props.errors.phone}</p> : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6}>
                                                <Form.Group className="mb-3" controlId="formSupplier">
                                                    <Form.Label>Jelszó:</Form.Label>
                                                    <Form.Control
                                                        onChange={props.handleChange}
                                                        name="password"
                                                        onBlur={props.handleBlur}
                                                        value={props.values.password}
                                                        type="password"
                                                        placeholder="Jelszó..."
                                                    />
                                                    {props.touched.password && props.errors.password ? <p className="input-error">{props.errors.password}</p> : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <Form.Group className="mb-3" controlId="formSupplier">
                                                    <Form.Label>Jelszó ismét:</Form.Label>
                                                    <Form.Control
                                                        onChange={props.handleChange}
                                                        name="confirmPassword"
                                                        onBlur={props.handleBlur}
                                                        value={props.values.confirmPassword}
                                                        type="password"
                                                        placeholder="Jelszó ismét..."
                                                    />
                                                    {props.touched.confirmPassword && props.errors.confirmPassword ? <p className="input-error">{props.errors.confirmPassword}</p> : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Button className="orange" type="submit">
                                            Create
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default CreateUser

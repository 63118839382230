//  Modules from the React eco-system
import { useContext } from "react"
import { useLocation } from "react-router"
import { HashLink } from "react-router-hash-link"
import { useTranslation } from "react-i18next";

// Context
import { UserContext } from "../../store/UserProvider"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { toggleIsSidebarNarrow } from "../../store/pageSlice"
import { RootState } from "../../store/store"

// Interfaces, enums
import { IBaseAdmin } from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Nav } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js"

// images
import logo from "../../assets/img/logo_transparentbg.png";

interface IProps {
    routes: any
    sidebarToggleClass: string
    setsidebarToggleClass: Function
}

/**
 *  Sidebar of the page
 */
function SideBar(props: IProps) {
    // dispatch for the redux
    const isSidebarNarrow = useSelector((state: RootState) => state.pageReducer.isSidebarNarrow)
    const dispatch = useDispatch()
    const location = useLocation()
    const { t } = useTranslation();

    //Logged in user
    const admin: IBaseAdmin = useContext(UserContext).user

    /**
     * check the path and send "active" to the className if the route is active
     * @param routeName 
     * @returns 
     */
    const activeRoute = (routeName: string) => {
        // get the page name from the url --> after the split of the pathname the firs item is empty string, the second is "admin", the third is the current page
        const pageFromPath = location.pathname.split("/")[2]
        return pageFromPath === routeName.toLowerCase() ? "active" : ""
    }

    return (
        <div className={`sidebar ${props.sidebarToggleClass} ${isSidebarNarrow ? "narrow-sidebar" : ""}`}>
            <div className="sidebar-wrapper">
                <div className="logo-container" onClick={() => dispatch(toggleIsSidebarNarrow())}>
                    <img className="logo" alt="logo-login" src={logo} />
                </div>
                <Nav>
                    {props.routes.map((route: any, key: number) => {
                        if (route.displayOnSidebar && route.permission.includes(admin.role))
                            return (
                                <li className={activeRoute(route.name)} key={key}>
                                    <HashLink
                                        onClick={() => {props.setsidebarToggleClass("hide")}}
                                        smooth
                                        to={{
                                            pathname: route.path.replace(":page?", ""),
                                            hash: "#",
                                        }}
                                        className="nav-link">
                                        <Icon path={route.icon} size={1.2} />
                                        <p>{t(route.name)}</p>
                                        <span className="chevron-icon">
                                            <Icon path={mdiChevronRight} size={1.2} />
                                        </span>
                                    </HashLink>
                                </li>
                            )
                        return null
                    })}
                </Nav>
            </div>
            {/* <div className={'toggle-sidebar' + (isSidebarNarrow ? '' : ' expanded')} onClick={() => dispatch(toggleIsSidebarNarrow())}>
                {isSidebarNarrow ?
                    <span>Expand<Icon path={mdiChevronRight} size={1} /></span> :
                    <span><Icon path={mdiChevronLeft} size={1} />Collapse</span>
                }
            </div> */}

        </div>
    )
}

export default SideBar
